// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-calendar-intro-js": () => import("./../../../src/pages/calendar-intro.js" /* webpackChunkName: "component---src-pages-calendar-intro-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order/success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-paintings-js": () => import("./../../../src/pages/paintings.js" /* webpackChunkName: "component---src-pages-paintings-js" */),
  "component---src-pages-scritti-js": () => import("./../../../src/pages/scritti.js" /* webpackChunkName: "component---src-pages-scritti-js" */),
  "component---src-templates-painting-js": () => import("./../../../src/templates/painting.js" /* webpackChunkName: "component---src-templates-painting-js" */),
  "component---src-templates-paper-js": () => import("./../../../src/templates/paper.js" /* webpackChunkName: "component---src-templates-paper-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

